import { BaseAdProviderService } from "../base-ad-provider";

export class InfolinksAdProviderService extends BaseAdProviderService {

    static attachScript(): Promise<void> {
        return new Promise((resolve, reject) => {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://resources.infolinks.com/js/infolinks_main.js';
            script.onload = function() {
                // Script has loaded and now you can set the infolinks properties
                (window as any).infolinks_pid = 3419114;
                (window as any).infolinks_wsid = 0;
                resolve();
            };
            script.onerror = function(err: any) {
                reject(err);
            }
            document.body.appendChild(script);
        });
    }

    protected async doInitialize(delay: number): Promise<void> {
        console.log("[InfolinksAdProvider] Loading");
        return InfolinksAdProviderService.attachScript();
    }
}
import { GameAchievementService } from "../achievements/game-achievement-service";
import { GoogleAdsProviderService } from "../ads-service/google/google-ads-service";
import { SOUND_PATHS } from "../sound-service/sounds";

export class PreloaderService {

    private static instance: PreloaderService;

    constructor() {
        if (PreloaderService.instance) {
            return PreloaderService.instance;
        }

        PreloaderService.instance = this;
    }

    initializePreload(): void {
        // these should be loaded quickly
        setTimeout(() => {
            this.preloadBoardSounds();
            this.preloadCoinImage();
            this.preloadGoogleAds();
        }, 1);

        // these shouldn't be loaded immediately
        setTimeout(() => {
            this.preloadAchievementsIcons();
            this.preloadGameGeneratorTemplates();
        }, 5_000);

        // these can be loaded much later
        setTimeout(() => {
            this.preloadMorePageImages();
        }, 10_000);
    }

    private preloadCoinImage(): void {
        const img = new Image();
        img.src = "./coin.png";
    }

    private preloadMorePageImages(): void {
        const imgUrls = [
            "./hexakai-book-cover.png",
            "./mm-cover.png",
            "./author.png"
        ];

        for (const path of imgUrls) {
            const img = new Image();
            img.src = path;
        }
    }

    private preloadBoardSounds(): void {
        for (const key of Object.keys(SOUND_PATHS)) {
            const audio = new Audio();
            // @ts-ignore
            audio.src = SOUND_PATHS[key];
            audio.preload = 'auto';
        }
    }

    private preloadAchievementsIcons(): void {
        for (const achievement of GameAchievementService.getAllAchievements()) {
            const image = new Image();
            image.src = achievement.iconUrl;
        }
    }

    private preloadGameGeneratorTemplates(): void {
        import("../../../static/templates-3.json");
        import("../../../static/templates-5.json");
        import("../../../static/templates-7.json");
        import("../../../static/templates-8.json");
        import("../../../static/templates-9.json");
        import("../../../static/templates-10.json");
        import("../../../static/templates-11.json");
        import("../../../static/templates-12.json");
        import("../../../static/templates-13.json");
        import("../../../static/templates-14.json");
        import("../../../static/templates-15.json");
        import("../../../static/templates-16.json");
    }

    private preloadGoogleAds(): void {
        GoogleAdsProviderService.attachGoogleAdsScript(
            GoogleAdsProviderService.AD_UNITS.fixedSmall
        );
    }
}
import { ClientService } from "../client-service/client-service";
import { ModalService } from "../modal-service/modal-service";
import { StorageService } from "../models/storage-service";
import { PromiseSubject } from "../promise-subject/promise-subject";
import { EventListenerEventsService } from "../user-events/event-listener-events-service";
import { boardGeneratedEvent } from "../user-events/events/board-generated-event";
import BUILD from "../client-service/b.json";
import { html } from "lit";
import { UserMetrics } from "../models/user-metrics";
import { HexakaiBoardData } from "../hexakai-board/hexakai-board-data";
import { HexakaiGameDifficulties, HexakaiGameParams } from "../models/hexakai-game-params";
import { boardStartedEvent } from "../user-events/events/board-started-event";
import { UserEvent } from "../models/analytics-event";
import { boardCompletedEvent, BoardCompletedEventData } from "../user-events/events/board-completed-event";
import { dailyBoardStartedEvent, DailyBoardStartedEventData } from "../user-events/events/daily-board-started-event";
import { dailyBoardCompletedEvent, DailyBoardCompletedEventData } from "../user-events/events/daily-board-completed-event";
import { GlobalAccessService } from "../global-access-service/global-access-service";
import { PromiseQueue } from "../promise-queue/promise-queue";

export class SessionService {

    private static instance: SessionService;

    // this indicates if we've had a session on this device
    private welcomeShownKey = "welcome-modal";
    private userMetricsKey = "user-metrics";
    private FIRST_MODAL_WAIT_TIME = 1500;
    private _isFirstAppSession = new PromiseSubject<boolean>();
    private metricsLoaded = new PromiseSubject<boolean>();
    private metricsCache!: UserMetrics;

    constructor(
        private clientService: ClientService,
        private modalService: ModalService,
        private storageService: StorageService,
        private eventListenerEventsService: EventListenerEventsService
    ) {
        if (SessionService.instance) {
            return SessionService.instance;
        }

        SessionService.instance = this;
        this.initialize();

        GlobalAccessService.getInstance().registerGlobalHandlers({
            sessionService: this
        });
    }

    isFirstAppSession(): Promise<boolean> {
        return this._isFirstAppSession.getPromise();
    }

    async getUserMetrics(): Promise<UserMetrics> {
        await this.metricsLoaded.getPromise();
        return this.metricsCache;
    }

    private async initialize(): Promise<void> {
        this.initFirstAppSession();
        this.initBuildRefresh();
        this.initUserMetrics();
    }

    private initBuildRefresh(): void {
        // check for refresh every so often
        const checkInterval = setInterval(async () => {
            const bNo = await fetch("/b.json")
                .then(res => res.json())
                .then(({ id }) => id)
                .catch(() => BUILD.id);

            if (bNo !== BUILD.id) {
                this.modalService.showModal(
                    "update",
                    "Update App",
                    html`
                <p>Please refresh this app to receive the latest updates.</p>
                <div class="refresh-row">
                    <styled-button @click="${() => window.location.href = window.location.href}">Refresh</styled-button>
                    <styled-button @click="${() => this.modalService.hideModal("update")}">Close</styled-button>
                </div>
            `
                );
                clearInterval(checkInterval);
            }
        }, 1_000 * 60 * 60 * 6);
    }

    private async initUserMetrics(): Promise<void> {
        const metricsCache = await this.storageService.get<UserMetrics>(this.userMetricsKey);
        const userMetrics: any = metricsCache || {};

        // fill in values and defaults for boards object
        const boards: any = {};
        for (const gameSize of HexakaiBoardData.GAME_SIZES) {
            const diffStats: any = {};
            for (const difficulty of HexakaiGameDifficulties) {
                diffStats[difficulty] = {
                    numStarted: 0,
                    numCompleted: 0,
                    ...userMetrics?.boards?.[gameSize]?.[difficulty]
                };
            }
            boards[gameSize] = diffStats;
        }

        // fill in values and defaults for streaks
        const lastDate = new Date().toLocaleDateString();
        const streaks = {
            boardStarted: {
                numDays: 0,
                lastDate,
                longestStreak: 0,
                ...userMetrics?.streaks?.boardStarted
            },
            boardCompleted: {
                numDays: 0,
                lastDate,
                longestStreak: 0,
                ...userMetrics?.streaks?.boardCompleted
            },
            dailyBoardStarted: {
                numDays: 0,
                lastDate,
                longestStreak: 0,
                ...userMetrics?.streaks?.dailyBoardStarted
            },
            dailyBoardCompleted: {
                numDays: 0,
                lastDate,
                longestStreak: 0,
                ...userMetrics?.streaks?.dailyBoardCompleted
            },
        };

        // update streaks if any out of date
        const date = new Date().toLocaleDateString();
        for (const [streakType, streak] of Object.entries(streaks)) {
            // update longest streak
            streak.longestStreak = Math.max(
                streak.longestStreak,
                streak.numDays
            );
            
            const dateDiff = this.getDayDiff(date, streak.lastDate);
            if (dateDiff > 1) {
                // set current streak to 0
                streak.numDays = 0;
            }
        }

        const dailyPuzzles = {
            numStarted: 0,
            numCompleted: 0,
            ...userMetrics.dailyPuzzles,
        };

        // add in any new defaults
        const metrics: UserMetrics = {
            ...userMetrics,
            boards,
            streaks,
            dailyPuzzles
        };

        this.metricsCache = metrics;
        this.metricsLoaded.resolve(true);
        await this.storageService.set(this.userMetricsKey, this.metricsCache);

        const boardListenKey = "session-metrics";
        // listen to board events
        this.eventListenerEventsService.onEvent(
            boardStartedEvent.eventName,
            (data: UserEvent<HexakaiGameParams>) => PromiseQueue.push(boardListenKey, async () => {
                const params = data.data;
                this.metricsCache.boards[params.gameSize][params.difficulty].numStarted++;
                this.updateStreak("boardStarted");
                this.storageService.set(this.userMetricsKey, this.metricsCache);
            }
        ));
        this.eventListenerEventsService.onEvent(
            boardCompletedEvent.eventName,
            (data: UserEvent<BoardCompletedEventData>) => PromiseQueue.push(boardListenKey, async () => {
                const params = data.data;
                this.metricsCache.boards[params.gameSize][params.difficulty].numCompleted++;
                this.updateStreak("boardCompleted");
                this.storageService.set(this.userMetricsKey, this.metricsCache);
            }
        ));
        this.eventListenerEventsService.onEvent(
            dailyBoardStartedEvent.eventName,
            (data: UserEvent<DailyBoardStartedEventData>) => PromiseQueue.push(boardListenKey, async () => {
                this.metricsCache.dailyPuzzles.numStarted ++;
                this.updateStreak("dailyBoardStarted");
                this.storageService.set(this.userMetricsKey, this.metricsCache);
            }
        ));
        this.eventListenerEventsService.onEvent(
            dailyBoardCompletedEvent.eventName,
            (data: UserEvent<DailyBoardCompletedEventData>) => PromiseQueue.push(boardListenKey, async () => {
                this.metricsCache.dailyPuzzles.numCompleted ++;
                this.updateStreak("dailyBoardCompleted");
                this.storageService.set(this.userMetricsKey, this.metricsCache);
            }
        ));
    }

    /**
     * Update the value of a streak metric
     */
    private updateStreak(streakType: string): void {
        const date = new Date().toLocaleDateString();
        const streak = (this.metricsCache.streaks as any)[streakType];

        if (streak.numDays === 0) {
            streak.lastDate = date;
            streak.numDays = 1;
        } else if (streak.lastDate !== date) {
            const dateDiff = this.getDayDiff(date, streak.lastDate);
            if (dateDiff === 1) {
                streak.lastDate = date;
                streak.numDays ++;
            } else if (dateDiff > 1) {
                streak.lastDate = date;
                streak.numDays = 1;
            }

            streak.longestStreak = Math.max(
                streak.longestStreak,
                streak.numDays
            );
        }
    }

    /**
     * Calculate the difference in days between two locale date strings
     */
    private getDayDiff(dateA: string, dateB: string): number {
        // Parse the date strings
        const parsedDate1 = new Date(Date.parse(dateA));
        const parsedDate2 = new Date(Date.parse(dateB));

        // Set both dates to UTC, so the time portion doesn’t interfere
        const utcDate1 = Date.UTC(parsedDate1.getFullYear(), parsedDate1.getMonth(), parsedDate1.getDate());
        const utcDate2 = Date.UTC(parsedDate2.getFullYear(), parsedDate2.getMonth(), parsedDate2.getDate());

        // Calculate the difference in milliseconds
        const timeDifference = Math.abs(utcDate2 - utcDate1);

        // Convert milliseconds to days
        return Math.abs(Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));
    }

    private async initFirstAppSession(): Promise<void> {

        // logic for first app session
        if (window.location.pathname === "/app" || window.location.pathname === "/") {
            const isShown = await this.storageService.get(this.welcomeShownKey) as any;
            this._isFirstAppSession.resolve(!isShown);

            if (!isShown || isShown.shown === false) {
                await this.clientService.resolveWhenDomInteractive();
                await this.storageService.set(this.welcomeShownKey, { shown: true });

                if (this.clientService.getConfig().tutorial.type === "interactive") {
                    const tutorialElement = document.createElement('hexakai-tutorial');
                    document.body.appendChild(tutorialElement);
                } else {
                    await new Promise((resolve) => setTimeout(resolve, this.FIRST_MODAL_WAIT_TIME));
                    const welcomeModal = document.createElement('hexakai-welcome-modal');
                    document.body.appendChild(welcomeModal);
                }
            }

            const tut = await this.storageService.get<any>("tutorial-in-progress");
            if (tut && tut.is) {
                const tutorialElement = document.createElement('hexakai-tutorial');
                document.body.appendChild(tutorialElement);
            }
        } else {
            this._isFirstAppSession.resolve(false);
        }
        this.eventListenerEventsService.onEvent(
            [boardGeneratedEvent.eventName],
            () => this._isFirstAppSession = new PromiseSubject<boolean>().resolve(false)
        );
    }
}